import React, { useState, useEffect } from "react";

const EmailList = () => {
  const [emails, setEmails] = useState([]);

  useEffect(() => {
    const fetchEmails = async () => {
      try {
        // TODO: Ganti dengan endpoint API sebenarnya
        const response = await fetch("https://zeromail.biz.id/api/emails");
        const data = await response.json();
        setEmails(data);
      } catch (error) {
        console.error("Gagal mengambil email:", error);
      }
    };

    fetchEmails();
  }, []);

  return (
    <div className="bg-white rounded-lg shadow-md mt-6">
      <h2 className="text-xl font-semibold p-4 border-b">Kotak Masuk</h2>
      <div className="divide-y">
        {emails.length === 0 ? (
          <div className="p-4 text-center text-gray-500">
            Belum ada email masuk
          </div>
        ) : (
          emails.map((email) => (
            <div key={email.id} className="p-4 hover:bg-gray-50 cursor-pointer">
              <div className="font-medium">{email.sender}</div>
              <div className="text-gray-600">{email.subject}</div>
              <div className="text-sm text-gray-400">
                {new Date(email.created_at).toLocaleString()}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default EmailList;
