// src/services/api.js
const API_BASE_URL = "https://zeromail.biz.id/api";

export const api = {
  async createMailbox() {
    const response = await fetch(`${API_BASE_URL}/mailbox`, {
      method: "POST",
    });
    return response.json();
  },

  async getEmails(email) {
    const response = await fetch(`${API_BASE_URL}/emails/${email}`);
    return response.json();
  },

  async getEmailById(mailboxId, emailId) {
    const response = await fetch(
      `${API_BASE_URL}/emails/${mailboxId}/${emailId}`
    );
    return response.json();
  },
};

export default api;
