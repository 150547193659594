// src/components/MailBox.js
import React, { useState } from "react";
import api from "../services/api";

const MailBox = () => {
  const [tempEmail, setTempEmail] = useState("");
  const [error, setError] = useState(null);

  const createNewEmail = async () => {
    try {
      setError(null);
      const data = await api.createMailbox();
      setTempEmail(data.email);
    } catch (error) {
      console.error("Error creating email:", error);
      setError("Gagal membuat email sementara. Silakan coba lagi.");
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {error}
        </div>
      )}
      {tempEmail ? (
        <div>
          <h2 className="text-xl font-semibold mb-2">Email Sementara Anda:</h2>
          <div className="flex items-center gap-4">
            <code className="bg-gray-100 p-2 rounded">{tempEmail}</code>
            <button
              onClick={() => navigator.clipboard.writeText(tempEmail)}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Salin
            </button>
          </div>
        </div>
      ) : (
        <button
          onClick={createNewEmail}
          className="bg-blue-500 text-white px-6 py-3 rounded-lg hover:bg-blue-600"
        >
          Buat Email Baru
        </button>
      )}
    </div>
  );
};

export default MailBox;
