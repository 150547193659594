import React from "react";
import { Header, MailBox, EmailList } from "./components";

function App() {
  return (
    <div className="min-h-screen bg-gray-100">
      <Header />
      <main className="container mx-auto px-4 py-8">
        <MailBox />
        <EmailList />
      </main>
    </div>
  );
}

export default App;
